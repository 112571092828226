import React, { useState, useEffect } from 'react'
import { CSSTransitionGroup } from 'react-transition-group'

import usePagination from '../../hooks/usePagination'
import useIntersect from '../../hooks/useIntersect'
import useMedia from '../../hooks/useMedia'
import { BlogCard } from 'components/BlogCard'
import { PaginationButton } from 'components/Collection/PaginationButton'
import './BlogCollection.scss'
import '../BlogCard/BlogCard.scss'

const BlogCollection = ({ blogs }) => {
  const [pageSize, setPageSize] = useState(12)
  const [headerIntersectRef, headerEntry] = useIntersect({})
  const [transitionDirection, setTransitionDirection] = useState('')
  const blogsPaginated = usePagination([...blogs], pageSize)

  const columnCount = useMedia(
    // Media queries
    [
      '(min-width: 1600px)',
      '(min-width: 1212px)',
      '(min-width: 580px)',
      '(min-width: 340px)',
      '(min-width: 1px)',
    ],
    // Column counts (relates to above media queries by array index)
    [4, 4, 3, 1, 1],
    // Default column count
    6
  )

  const handlePrevious = (e) => {
    e.preventDefault()
    setTransitionDirection('right')
    blogsPaginated.prev()
  }

  const handleNext = (e) => {
    e.preventDefault()
    setTransitionDirection('left')
    blogsPaginated.next()
  }

  // resetting to first page on renders prevents loading empty pages if the window size increases
  useEffect(() => {
    setPageSize(columnCount * 1)
  }, [columnCount])

  return (
    <div className='iliad__blog-collection--section'>
      <div hStart={2} hSpan={12} className='iliad__blog-collection--header'>
        <div ref={headerIntersectRef}>
          <div>
            <h2>
              Articles <span>({blogs.length})</span>
            </h2>
            <div className='iliad__blog-collection--controls'>
              <PaginationButton
                variant='prev'
                disabled={blogsPaginated.currentPage <= 1}
                onClick={handlePrevious}
                aria-label='Click to navigate to the previous page'
              />
              <span id='page-indicators'>
                <span className='iliad__display--no-mobile'>Page&nbsp;</span>
                <span>{blogsPaginated.currentPage} /&nbsp;</span>
                {blogsPaginated.totalPages}
              </span>
              <PaginationButton
                disabled={
                  blogsPaginated.currentPage >= blogsPaginated.totalPages
                }
                onClick={handleNext}
                aria-label='Click to navigate to the next page'
              />
            </div>
          </div>
        </div>
        <div
          className={`iliad__blog-collection--wrapper ${
            headerEntry.isIntersecting ? 'animation-blog-collection' : ''
          }`}>
          <div className='iliad__blog-collection--container'>
            <CSSTransitionGroup
              transitionName={`blogs-${transitionDirection}`}
              transitionEnterTimeout={600}
              transitionLeaveTimeout={600}>
              <div
                key={blogsPaginated.currentPage}
                className='iliad__blog-collection--page'>
                <div className='iliad__blog-collection--subgrid'>
                  {blogsPaginated.currentData().map((blog, index) => {
                    const { title, author, headerImage } =
                      blog.childMarkdownRemark.frontmatter
                    const { slug } = blog.childMarkdownRemark.fields

                    return (
                      <div
                        style={{ gridColumnEnd: `span ${12 / columnCount}` }}>
                        <BlogCard
                          key={`blog-card-${title}`}
                          slug={slug}
                          title={title}
                          author={author}
                          headerImage={
                            headerImage?.childImageSharp.gatsbyImageData
                          }
                          index={index}
                          isLast={blogs.length - 1}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </CSSTransitionGroup>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogCollection
