import React from 'react'
import { Link } from 'gatsby'
import { BackgroundImage } from 'components/BackgroundImage'

export const BlogCard = ({ title, author, headerImage, slug }) => {
  return (
    <Link
      to={`/news${slug}`}
      className='iliad__blog-collection--card-container'>
      <BackgroundImage
        className='iliad__blog-card'
        backgroundColor='000000'
        image={headerImage}>
        <div className='iliad__blog-card--overlay' />
        <div className='iliad__blog-card--title'>
          <h4 dangerouslySetInnerHTML={{ __html: title }} />—{' '}
          <span className='iliad__blog-card--author'>{author}</span>
        </div>
      </BackgroundImage>
    </Link>
  )
}
